import React from 'react'
import { observer } from 'mobx-react'

import factoryState from '/state/factoryState'
import Truck from '/component/base/Truck'

import { StyledTruckPositioner } from './TruckPositioner.styles'

const TruckPositioner = () => {
  const doorOpen = factoryState.currentStep !== 'complete'

  return (
    <StyledTruckPositioner
      isOnScreen={factoryState.currentStep === 'charitySelection'}
      isComplete={factoryState.currentStep === 'complete'}
    >
      <Truck doorOpen={doorOpen} doorCloseDelay={1} doorOpenDelay={1} />
    </StyledTruckPositioner>
  )
}

export default observer(TruckPositioner)
