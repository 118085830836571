import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import BoltSvg from '/asset/svg/assemblyLine/bolt.svg'

export const StyledControls = styled(motion.div)`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;

  @media (min-width: 480px) {
    max-width: 450px;
    bottom: 3vh;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }
`

export const Navigation = styled.nav`
  padding: 0 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: #f1f2da;
  }
`

export const GiftOptionsConfirmation = styled(motion.div)`
  position: relative;
  margin: 0 16px 3vh;
  color: #f1f2da;
  text-align: center;

  p:first-child {
    margin-bottom: 8px;
  }
`

export const GiftOptionsWrapper = styled(motion.div)`
  position: relative;
  margin: 0 16px 3vh;
`

export const GiftOptionsDepth = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  top: -4px;
  border-radius: 15px 15px 0 0;
  height: 30px;
  z-index: 1;
  background: linear-gradient(
    270deg,
    #9f864e 0%,
    #edc35f 38.89%,
    #edc35f 61.09%,
    #9f864e 100%
  );
`

export const GiftOptions = styled.menu`
  background: linear-gradient(180deg, #ffffff 0%, #edd49a 100%);
  border-radius: 10px;
  z-index: 2;
  position: relative;
  padding: 4% 10%;
`

export const Bolt = styled(BoltSvg)`
  width: 6px;
  position: absolute;

  @media (min-width: 480px) {
    width: 9px;
  }

  @media (min-width: 768px) {
    width: 12px;
  }

  ${(props) =>
    props.position === 'tl' &&
    css`
      top: 9%;
      left: 2%;
    `}

  ${(props) =>
    props.position === 'tr' &&
    css`
      top: 9%;
      right: 2%;
    `}

  ${(props) =>
    props.position === 'br' &&
    css`
      bottom: 9%;
      right: 2%;
    `}

  ${(props) =>
    props.position === 'bl' &&
    css`
      bottom: 9%;
      left: 2%;
    `}
`

export const OptionsInner = styled(motion.div)`
  display: flex;
  justify-content: space-between;
`
