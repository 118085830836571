import styled from 'styled-components'

export const TagWrapper = styled.div`
  position: absolute;
  top: 45%;
  left: 75.5%;
  width: 39%;

  > div {
    display: flex;
    justify-content: center;
    transform-origin: 26% top;
  }

  img {
    vertical-align: bottom;
  }
`
