import React, { useEffect, useState } from 'react'

import useWindowSize from '/hook/useWindowSize'
import factoryState from '/state/factoryState'

import { StyledConveyor, LeftEndcap, RightEndcap } from './ConveyorBelt.styles'
import Roller from './Roller'
import { observer } from 'mobx-react'

const calculateRollerCountForWindowSize = ({ width, height }) => {
  if (width < 1024) {
    return 7
  } else if (width < 1440 && height < 900) {
    return 12
  } else if (height > 900) {
    return 9
  } else {
    return 12
  }
}

const ConveyorBelt = () => {
  const windowSize = useWindowSize()
  const [rollerCount, setRollerCount] = useState(
    calculateRollerCountForWindowSize(windowSize),
  )

  useEffect(() => {
    setRollerCount(calculateRollerCountForWindowSize(windowSize))
  }, [windowSize])

  const variants = {
    idle: {},
    forward: {
      x: 0,
      transition: {
        type: 'inertia',
        velocity: -6,
        timeConstant: 200,
        modifyTarget: (target) => {
          return target - 80
        },
      },
    },
    backward: {
      x: 0,
      transition: {
        type: 'inertia',
        velocity: 6,
        timeConstant: 200,
        modifyTarget: (target) => {
          return target + 80
        },
      },
    },
    endOfLine: {
      x: '-90vw',
      transition: {
        type: 'tween',
        duration: 0.5,
      },
    },
  }

  let animate
  if (
    factoryState.currentStep === 'charitySelection' ||
    factoryState.currentStep === 'complete'
  ) {
    animate = 'endOfLine'
  } else if (factoryState.conveyorRolling) {
    animate = factoryState.conveyorRolling
  } else {
    animate = 'idle'
  }

  return (
    <StyledConveyor variants={variants} animate={animate} initial="idle">
      <LeftEndcap>
        <Roller />
      </LeftEndcap>
      {Array(rollerCount)
        .fill(null)
        .map((_, i) => {
          return <Roller key={`roller${i}`} />
        })}
      <RightEndcap>
        <Roller />
      </RightEndcap>
    </StyledConveyor>
  )
}

export default observer(ConveyorBelt)
