import styled from 'styled-components'

export const TopperWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: -1;

  > div {
    display: flex;
    justify-content: center;
    transform-origin: bottom center;
  }

  img {
    vertical-align: bottom;
  }
`
