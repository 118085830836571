import { observer } from 'mobx-react'
import React, { useRef } from 'react'

import factoryState from '/state/factoryState'
import { randomListBetween } from '/util/random'
import Gift from '/component/base/Gift'

import {
  GiftBouncer,
  GiftXPositioner,
  GiftTruckLoadingPositioner,
  GiftShaker,
} from './GiftPositioner.styles'

const GiftPositioner = () => {
  const ref = useRef(null)

  const bouncerVariants = {
    before: {
      y: '-200%',
      rotate: -3,
      transition: {
        type: 'spring',
        mass: 3,
        damping: 60,
        stiffness: 200,
      },
    },
    during: {
      y: 0,
      rotate: [1, -1, 1, 0, 0],
      marginBottom: [3, 8, 6, 2, 0],
      transition: {
        type: 'tween',
      },
    },
    after: {
      transition: {
        type: 'spring',
        mass: 5,
        damping: 60,
        stiffness: 150,
      },
    },
  }

  let bouncerAnimate
  switch (factoryState.currentStep) {
    case 'envelope':
    case 'welcomeMessage':
      bouncerAnimate = 'before'
      break
    case 'charitySelection':
    case 'complete':
      bouncerAnimate = 'after'
      break
    default:
      bouncerAnimate = 'during'
  }

  const shakerVariants = {
    rolling: {
      x: randomListBetween(-1, 1, 9),
      y: randomListBetween(-2, 1, 9),
      rotate: randomListBetween(-0.5, 0.5, 9),
      transition: {
        repeatType: 'mirror',
        repeat: Infinity,
        stiffness: 100,
      },
    },
    idle: {
      x: 0,
      y: 0,
      rotate: 0,
      transition: {
        mass: 50,
        damping: 1,
      },
    },
  }

  const shakerAnimate = !factoryState.conveyorRolling ? 'idle' : 'rolling'

  return (
    <GiftBouncer ref={ref} variants={bouncerVariants} animate={bouncerAnimate}>
      <GiftXPositioner
        before={bouncerAnimate === 'before'}
        during={bouncerAnimate === 'during'}
        after={bouncerAnimate === 'after'}
      >
        <GiftTruckLoadingPositioner
          initial="false"
          animate={
            factoryState.currentStep === 'complete' ? 'truck' : 'conveyor'
          }
          variants={{
            conveyor: {
              x: '0%',
              y: ['44%', '-20%', '-50%', '-30%', '0%', '-3%', '0%'],
              opacity: 1,
              transition: {
                type: 'tween',
                ease: 'linear',
                duration: 0.7,
                delay: 1.2,
                opacity: {
                  delay: 0.5,
                },
              },
            },
            truck: {
              rotate: [0, 0, 0, -8, -4, 0, 2, 0],
              y: ['0%', '0%', '0%', '-30%', '-50%', '-20%', '47%'],
              x: '150%',
              opacity: 0,
              transition: {
                type: 'tween',
                ease: 'linear',
                duration: 0.7,
                opacity: {
                  delay: 1.4,
                },
              },
            },
          }}
        >
          <GiftShaker variants={shakerVariants} animate={shakerAnimate}>
            <Gift />
          </GiftShaker>
        </GiftTruckLoadingPositioner>
      </GiftXPositioner>
    </GiftBouncer>
  )
}

export default observer(GiftPositioner)
