import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { AnimatePresence } from 'framer-motion'

import participantState from '/state/participantState'
import apiClient from '/util/apiClient'
import giftState from '/state/giftState'
import factoryState from '/state/factoryState'
import CharityButton from '/component/base/CharityButton'
import Button from '/component/base/Button'

import { Wrapper, CharityList, ButtonWrapper } from './CharityPicker.styles'

const CharityPicker = () => {
  const [processing, setProcessing] = useState(false)
  const variants = {
    hidden: {
      opacity: -0.2,
    },
    visible: {
      opacity: 1,
    },
  }

  const animate =
    factoryState.currentStep === 'charitySelection' ? 'visible' : 'hidden'

  const handleCbClick = (charity) => {
    giftState.setCharityName(charity.name)
    giftState.setCharityUrl(charity.url)
  }

  const handleSendClick = async () => {
    if (participantState.name && !participantState.submissionComplete) {
      setProcessing(true)
      try {
        await apiClient.addResponse({
          email: participantState.email,
          charityName: giftState.charityName,
          paper: giftState.paper,
          topper: giftState.topper,
          tag: giftState.tag,
        })
        participantState.markSubmissionComplete()
        factoryState.goNext()
      } catch (_err) {
        alert('Something went wrong! Please try again.')
      }
    } else {
      factoryState.goNext()
    }
  }

  return (
    <AnimatePresence>
      {factoryState.currentStep === 'charitySelection' &&
        participantState.charities.length > 0 &&
        !participantState.submissionComplete && (
          <div>
            <Wrapper
              variants={variants}
              animate={animate}
              initial="hidden"
              exit="hidden"
            >
              <CharityList>
                {participantState.charities.map((charity) => {
                  return (
                    <CharityButton
                      key={charity.name}
                      title={charity.name}
                      infoUrl={charity.url}
                      selected={giftState.charityName === charity.name}
                      dimmed={
                        giftState.charityName !== charity.name &&
                        giftState.charityName !== null
                      }
                      onClick={() => handleCbClick(charity)}
                    />
                  )
                })}
              </CharityList>
              <ButtonWrapper>
                <Button
                  onClick={handleSendClick}
                  disabled={giftState.charityName === null || processing}
                >
                  Send Gift
                </Button>
              </ButtonWrapper>
            </Wrapper>
          </div>
        )}
    </AnimatePresence>
  )
}

export default observer(CharityPicker)
