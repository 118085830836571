import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: none;
  z-index: 999;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: radial-gradient(88.75% 50% at 50% 50%, #2a308b 0%, #1c1530 100%);
  color: #f1f2da;
  padding: 10%;
  text-align: center;

  p {
    max-width: 400px;

    @media (min-width: 768px) {
      max-width: 600px;
    }
  }

  @media (max-height: 430px),
    (min-width: 480px) and (max-height: 480px),
    (min-width: 1260px) and (max-height: 600px) {
    display: flex;
  }
`
