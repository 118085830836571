import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

export const Wrapper = styled(motion.div)`
  cursor: pointer;
  background: transparent;
  position: relative;
`

export const StyledCharityButton = styled.button`
  position: relative;
  transition: all 60ms ease-out;
  display: block;
  width: 100%;
  min-height: 70px;
  height: 100%;
  text-align: left;
  padding: 4% 20% 4% 5%;
  border-radius: 8px;
  background: #f1f2da;
  opacity: 1;
  transition: all 150ms ease-in-out;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;

  @media (min-width: 480px) {
    font-size: 20px;
  }

  @media (min-width: 1260px) {
    font-size: 26px;
  }

  ${(props) =>
    props.dimmed &&
    css`
      opacity: 0.5;
    `}
`

export const Depth = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, #edc25f 0%, #9f864e 100%);
  z-index: 1;
  border-radius: 10px;
  transition: all 120ms ease-out;
  opacity: 1;

  ${(props) =>
    props.dimmed &&
    css`
      opacity: 0.5;
    `}

  ${(props) =>
    props.selected &&
    css`
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
    `}
`

export const InfoLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 4.5%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;

  svg {
    width: 20px;
    display: block;

    @media (min-width: 480px) {
      width: 30px;
    }
  }

  @media (min-width: 960px) {
    width: 40px;
    height: 40px;
  }
`
