import styled from 'styled-components'
import { motion } from 'framer-motion'

export const LidWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`

export const LidShaker = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  line-height: 0;

  img {
    vertical-align: bottom;
  }
`
