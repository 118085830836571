import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const StyledFlap = styled(motion.div)`
  width: 100%;
  transform-style: preserve-3d;
  position: relative;
  transform-origin: top center;
  position: absolute;

  &:after {
    content: '';
    display: block;
    padding-top: 43.8%;
  }
`

export const FrontFlap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`
export const BackFlap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotateX(180deg);
  backface-visibility: hidden;

  ${(props) =>
    props.open &&
    css`
      opacity: 0;
    `}
`
