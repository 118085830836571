import React from 'react'
import { observer } from 'mobx-react'
import { AnimatePresence } from 'framer-motion'

import participantState from '/state/participantState'
import factoryState from '/state/factoryState'
import giftState from '/state/giftState'
import Button from '/component/base/Button'

import { Wrapper, Header, Paragraph } from './ThankYou.styles'
import GiftPositioner from './GiftPositioner'

const ThankYou = () => {
  const variants = {
    hidden: {
      opacity: -0.3,
      transition: {
        duration: 0.2,
      },
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 2,
      },
    },
  }

  const animate = factoryState.currentStep === 'complete' ? 'visible' : 'hidden'

  const headerMsg = participantState.name
    ? `Thank you, ${participantState.name}!`
    : 'Thanks for Wrapping!'

  return (
    <AnimatePresence>
      {factoryState.currentStep === 'complete' && (
        <Wrapper
          variants={variants}
          animate={animate}
          initial="hidden"
          exit="hidden"
          anonymous={!participantState.name}
        >
          <GiftPositioner />
          <Header>{headerMsg}</Header>
          {participantState.name && (
            <>
              <Paragraph>
                Your community thanks you for the gift, and we thank you for
                your partnership. Wishing you and yours a healthy,
                happy&nbsp;holidays!
              </Paragraph>
              <Paragraph>
                Your donation will benefit {giftState.charityName}. Learn about
                their work by visiting their&nbsp;website.
              </Paragraph>
              <Button href={giftState.charityUrl}>Learn More</Button>
            </>
          )}

          {!participantState.name && (
            <>
              <Paragraph>
                On behalf of everyone at MetaLab, have a wonderful holiday
                season and start to&nbsp;2021!
              </Paragraph>
            </>
          )}
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

export default observer(ThankYou)
