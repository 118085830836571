import React from 'react'
import { observer } from 'mobx-react'

import giftState from '/state/giftState'
import { capitalize } from '/util/string'

import { OptionsInner } from '../Controls.styles'

import RadioButton from '/component/base/RadioButton'

const GiftOptions = ({ options, optionTitle, optionKey }) => {
  const handleClick = (option) => {
    if (giftState.get(optionKey) === option.name && optionKey !== 'paper') {
      giftState[`set${optionTitle}`](null)
    } else {
      giftState[`set${optionTitle}`](option.name)
    }
  }

  const variants = {
    initial: (i) => {
      return {
        opacity: 0,
        scale: 1.2,
        y: '-50%',
        transition: {
          delay: i * 0.05,
        },
      }
    },
    tapped: {
      scale: 0.95,
      transition: {
        delay: 0,
      },
    },
    hovering: {
      scale: 1.05,
      transition: {
        delay: 0,
      },
    },
    exit: (i) => {
      return {
        opacity: -0.5,
        scale: 0.4,
        y: '50%',
        transition: {
          delay: i * 0.05,
        },
      }
    },
    animate: (i) => {
      return {
        opacity: 1,
        scale: 1,
        y: '0',
        transition: {
          delay: i * 0.05,
        },
      }
    },
  }

  return (
    <OptionsInner>
      {options.map((option, i) => {
        return (
          <RadioButton
            key={option.name}
            name={`${capitalize(option.name)} ${optionTitle}`}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            whileTap="tapped"
            whileHover="hovering"
            custom={i}
            selected={giftState[optionKey] === option.name}
            onClick={() => handleClick(option)}
          >
            <div
              style={{
                borderRadius: '900px',
                overflow: 'hidden',
              }}
            >
              <img
                alt=""
                src={option.iconSrc}
                style={{
                  verticalAlign: 'bottom',
                  width: '100%',
                  pointerEvents: 'none',
                }}
              />
            </div>
          </RadioButton>
        )
      })}
    </OptionsInner>
  )
}

export default observer(GiftOptions)
