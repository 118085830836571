import React from 'react'
import { TagWrapper } from './Tag.styles'

import classicTagPng from '/asset/png/tag/classic.png'
import ornamentTagPng from '/asset/png/tag/ornament.png'
import snowGolemTagPng from '/asset/png/tag/snowGolem.png'
import treeTagPng from '/asset/png/tag/tree.png'

import giftState from '/state/giftState'
import { observer } from 'mobx-react'
import { AnimatePresence, motion } from 'framer-motion'

const Tag = () => {
  const tagStyle = { width: '100%', height: '100%' }

  return (
    <TagWrapper>
      <AnimatePresence exitBeforeEnter>
        {giftState.tag && (
          <motion.div
            exit={{ opacity: -0.2, y: '5%', x: 0, rotate: 10 }}
            initial={{ opacity: -0.2, scale: 0.7, y: '20%', rotate: -50 }}
            animate={{ opacity: 1, scale: 1, y: 0, rotate: 0 }}
            key={giftState.tag}
          >
            {giftState.tag === 'classic' && (
              <img src={classicTagPng} alt="Classic tag" style={tagStyle} />
            )}
            {giftState.tag === 'ornament' && (
              <img src={ornamentTagPng} alt="Ornament tag" style={tagStyle} />
            )}
            {giftState.tag === 'snowGolem' && (
              <img
                src={snowGolemTagPng}
                alt="Snow golem tag"
                style={tagStyle}
              />
            )}
            {giftState.tag === 'tree' && (
              <img src={treeTagPng} alt="Tree tag" style={tagStyle} />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </TagWrapper>
  )
}

export default observer(Tag)
