import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const GiftBouncer = styled(motion.div)`
  width: 200px;
  z-index: 1;
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-top: 52%;
  }

  @media (min-width: 360px) {
    width: 220px;
  }

  @media (min-width: 480px) {
    width: 280px;
  }

  @media (min-width: 1260px) and (min-height: 900px) {
    width: 500px;
  }
`

export const GiftXPositioner = styled.div`
  transition: transform 600ms ease-in-out;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  ${(props) =>
    props.before &&
    css`
      transform: translateX(45vw);
    `}

  ${(props) =>
    props.during &&
    css`
      transform: translateX(calc(50vw - 50%));
    `}

  ${(props) =>
    props.after &&
    css`
      transform: translateX(calc(90vw - 110%));
    `}
`

export const GiftTruckLoadingPositioner = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`

export const GiftShaker = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`
