import { makeAutoObservable } from 'mobx'
import qs from 'qs'

import apiClient from '/util/apiClient'

class ParticipantState {
  isInitialized = false
  name = null
  email = null
  charities = []
  submissionComplete = false

  constructor() {
    makeAutoObservable(this)
    this.init()
  }

  markSubmissionComplete = () => {
    this.submissionComplete = true
    window.localStorage.submissionComplete = 'true'
  }

  init = async () => {
    const pid =
      qs.parse(window.location.search, { ignoreQueryPrefix: true }).pid ||
      window.localStorage.pid

    if (window.localStorage.submissionComplete === 'true') {
      this.submissionComplete = true
    }

    // Clear out query string, we're done with it. If someone copies the URL
    // and shares it, this way the share-ee(??) will get the anonymous flow.
    window.history.replaceState({}, '', window.location.origin)

    // Stuff the participant info into localStorage, so we can "always" know who
    // this user is, regardless of data from the URL.
    if (pid) {
      window.localStorage.pid = pid
    }

    try {
      const participant = await apiClient.getParticipant(pid)
      this.name = participant.name
      this.email = participant.email
      this.setCharities(participant.charities)
    } catch (err) {
      // noop, just continue with the anonymous flow 😎
    }

    this.isInitialized = true
  }

  setCharities = (charities) => {
    this.charities = charities
  }
}

export default new ParticipantState()
