import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

export const Wrapper = styled(motion.div)`
  position: relative;
  width: 40px;
  height: 44px;
  opacity: 1;
  transition: opacity 120ms ease-out;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
    `}

  @media (min-width: 360px) {
    width: 48px;
    height: 53px;
  }

  @media (min-width: 480px) {
    width: 60px;
    height: 66px;
  }
`

export const Depth = styled.div`
  z-index: 1;
  background: linear-gradient(180deg, #edc25f 0%, #9f864e 70.83%);
  width: 100%;
  height: calc(50% - 2px);
  position: absolute;
  top: 0;
  border-radius: 150px 150px 0 0;
`

export const StyledIconButton = styled.button`
  width: 100%;
  height: calc(100% - 4px);
  transform: translateY(4px);
  z-index: 2;
  background: linear-gradient(180deg, #ffffff 0%, #eddfbe 100%);
  border-radius: 150px;
  cursor: pointer;
  position: relative;
  transition: all 60ms ease-out;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 60%;
  }

  &:after {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 150ms ease-out;
    pointer-events: none;
    border-radius: 150px;
    background-color: rgba(255, 255, 255, 0.12);
    mix-blend-mode: lighten;
  }

  &:hover:after {
    opacity: 1;
  }

  &:active {
    transform: translateY(2px);
  }

  /* &:focus {
    box-shadow: 0 -4px #2b7038, inset 0 0 3px 1px rgba(241, 242, 218, 0.6);

    &:active {
      box-shadow: 0 -2px #2b7038;
    }
  } */
`
