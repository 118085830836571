import { observer } from 'mobx-react'
import React from 'react'

import participantState from '/state/participantState'
import factoryState from '/state/factoryState'
import Button from '/component/base/Button'

import { LetterWrapper, StyledLetter } from './Letter.styles'

const Letter = () => {
  const variants = {
    hidden: {
      y: '100%',
      transition: {
        type: 'spring',
        mass: 1.5,
        damping: 25,
        stiffness: 70,
      },
    },
    visible: {
      y: 20,
      transition: {
        type: 'spring',
        mass: 1.5,
        damping: 25,
        stiffness: 100,
        delay: 0.4,
      },
    },
  }

  let animate
  switch (factoryState.currentStep) {
    case 'envelope':
      animate = 'hidden'
      break
    default:
      animate = 'visible'
  }

  const goNext = () => factoryState.goNext()

  const greeting = participantState.name
    ? `Hi there, ${participantState.name}!`
    : 'Hi there!'

  const message =
    participantState.name && !participantState.submissionComplete
      ? `Thanks for helping us wrap your gift in style. Choose your favorite
          wrapping paper, topper, and gift tag, then select a charity to send it
          to.`
      : `It's time to wrap your gift in style. Choose your favorite
          wrapping paper, topper, and gift tag, and we'll send it out!`

  return (
    <LetterWrapper>
      <StyledLetter variants={variants} initial="hidden" animate={animate}>
        <p>
          <strong>{greeting}</strong>
        </p>

        <p>{message}</p>

        <Button onClick={goNext}>Start Wrapping</Button>
      </StyledLetter>
    </LetterWrapper>
  )
}

export default observer(Letter)
