import React from 'react'
import { observer } from 'mobx-react'
import { AnimatePresence } from 'framer-motion'

import { LidWrapper, LidShaker } from './Lid.styles'

import goldLidPng from '/asset/png/paper/lid/gold.png'
import redLidPng from '/asset/png/paper/lid/red.png'
import whiteLidPng from '/asset/png/paper/lid/white.png'

import giftState from '/state/giftState'

const Lid = ({ children }) => {
  const lidStyle = { width: '100%', height: '100%' }

  let lidColor
  switch (giftState.paper) {
    case 'deer':
    case 'flower':
      lidColor = 'red'
      break
    case 'house':
      lidColor = 'gold'
      break
    case 'ornament':
      lidColor = 'white'
      break
    default:
      break
  }

  return (
    <LidWrapper>
      <AnimatePresence>
        {giftState.paper && (
          <LidShaker
            exit={{ opacity: -0.5, scale: 0.3, y: '10%' }}
            initial={{ opacity: -0.5, scale: 0.8, y: '700%', rotate: -5 }}
            animate={{ opacity: 1, scale: 1, y: '10%', rotate: [1, -1, 1, 0] }}
            key={giftState.paper}
          >
            {lidColor === 'gold' && (
              <img src={goldLidPng} alt="Gold lid" style={lidStyle} />
            )}
            {lidColor === 'red' && (
              <img src={redLidPng} alt="Red lid" style={lidStyle} />
            )}
            {lidColor === 'white' && (
              <img src={whiteLidPng} alt="White lid" style={lidStyle} />
            )}

            {children}
          </LidShaker>
        )}
      </AnimatePresence>
    </LidWrapper>
  )
}

export default observer(Lid)
