import { observer } from 'mobx-react'
import React, { useRef } from 'react'

import factoryState from '/state/factoryState'
import FlapClosedSvg from '/asset/svg/envelope/flapClosed.svg'
import FlapOpenSvg from '/asset/svg/envelope/flapOpen.svg'

import { StyledFlap, FrontFlap, BackFlap } from './Flap.styles'

const Flap = () => {
  const ref = useRef(null)

  const variants = {
    closed: {
      rotateX: 0,
      zIndex: 4,
      translateZ: 1,
      transition: {
        type: 'tween',
        duration: 0.6,
        zIndex: {
          delay: 0.3,
        },
      },
    },
    open: {
      rotateX: 180,
      zIndex: 1,
      translateZ: 0,
      transition: {
        type: 'tween',
        duration: 0.6,
        zIndex: {
          delay: 0.3,
        },
      },
    },
  }

  let animate
  switch (factoryState.currentStep) {
    case 'envelope':
      animate = 'closed'
      break
    default:
      animate = 'open'
  }

  return (
    <StyledFlap
      ref={ref}
      variants={variants}
      initial="closed"
      animate={animate}
    >
      <FrontFlap>
        <FlapClosedSvg style={{ width: '100%' }} />
      </FrontFlap>
      <BackFlap open={factoryState.currentStep === 'open'}>
        <FlapOpenSvg style={{ width: '100%' }} />
      </BackFlap>
    </StyledFlap>
  )
}

export default observer(Flap)
