import { configure } from 'mobx'

const configurificate = () => {
  configure({
    enforceActions: true,
    computedRequiresReaction: true,
    reactionRequiresObservable: false,
    observableRequiresReaction: true,
    disableErrorBoundaries: false,
  })
}

export default configurificate
