import React from 'react'

import giftState from '/state/giftState'

import {
  StyledProgressBar,
  ProgressBarTrack,
  ProgressBarBar,
} from './ProgressBar.styles'
import { observer } from 'mobx-react'

const ProgressBar = () => {
  let progressPercent = 0
  if (giftState.paper && giftState.topper && giftState.tag) {
    progressPercent = 100
  } else if (giftState.paper && giftState.topper) {
    progressPercent = 66
  } else if (giftState.paper) {
    progressPercent = 33
  }

  return (
    <StyledProgressBar>
      <ProgressBarTrack>
        <ProgressBarBar progressPercent={progressPercent} />
      </ProgressBarTrack>
    </StyledProgressBar>
  )
}

export default observer(ProgressBar)
