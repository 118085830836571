import React from 'react'
import { observer } from 'mobx-react'

import InfoSvg from '/asset/svg/icon/info.svg'

import {
  Wrapper,
  Depth,
  StyledCharityButton,
  InfoLink,
} from './CharityButton.styles'

const CharityButton = ({ title, infoUrl, selected, dimmed, ...restProps }) => {
  return (
    <Wrapper
      whileTap={{ y: '2%' }}
      transition={{ duration: 0.1, type: 'tween' }}
      dimmed={dimmed}
    >
      <StyledCharityButton {...restProps} dimmed={dimmed}>
        {title}
      </StyledCharityButton>
      <InfoLink
        href={infoUrl}
        target="_blank"
        rel="noreferrer"
        aria-label={`Visit the ${title} website`}
      >
        <InfoSvg />
      </InfoLink>
      <Depth selected={selected} dimmed={dimmed} />
    </Wrapper>
  )
}

export default observer(CharityButton)
