import {
  GET_PARTICIPANT,
  CREATE_ANONYMOUS_RESPONSE,
  CREATE_RESPONSE,
} from '/constant/apiUrls'

class ApiClient {
  _get = async (url) => {
    const res = await fetch(url)
    return await res.json()
  }

  _post = async (url, body) => {
    return await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })
  }

  getParticipant = async (pid) => {
    const url = new URL(GET_PARTICIPANT)
    url.searchParams.set('pid', pid)
    return await this._get(url)
  }

  addResponse = async ({ email, charityName, paper, topper, tag }) => {
    const url = new URL(CREATE_RESPONSE)
    return await this._post(url, { email, charityName, paper, topper, tag })
  }

  addAnonymousResponse = async ({ paper, topper, tag }) => {
    const url = new URL(CREATE_ANONYMOUS_RESPONSE)
    return await this._post(url, { paper, topper, tag })
  }
}

export default new ApiClient()
