import styled from 'styled-components'

export const StyledButton = styled.button`
  padding: 7px 24px 8px;
  line-height: 1.5;
  text-decoration: none;
  color: #f1f2da;
  background: linear-gradient(180deg, #3aa76d 0%, #2b7038 100%);
  border-radius: 8px;
  box-shadow: 0 -4px #2b7038;
  cursor: pointer;
  position: relative;
  transition: all 60ms ease-out;
  font-size: 16px;

  @media (min-width: 480px) {
    font-size: 20px;
  }

  @media (min-width: 1260px) {
    font-size: 32px;
  }

  &:after {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 150ms ease-out;
    pointer-events: none;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.12);
    mix-blend-mode: lighten;
  }

  &:hover:after {
    opacity: 1;
  }

  &:active {
    box-shadow: 0 -2px #2b7038;
    transform: translateY(-2px);
  }

  &:disabled {
    opacity: 30%;
  }

  /* &:focus {
    box-shadow: 0 -4px #2b7038, inset 0 0 3px 1px rgba(241, 242, 218, 0.6);

    &:active {
      box-shadow: 0 -2px #2b7038;
    }
  } */
`
