import React from 'react'

import factoryState from '/state/factoryState'

import ConveyorBelt from './ConveyorBelt'
import Controls from './Controls'
import {
  AssemblyLineWrapper,
  StyledAssemblyLine,
  AssemblyLineYPositioner,
} from './AssemblyLine.styles'
import GiftPositioner from './GiftPositioner'
import { observer } from 'mobx-react'
import Header from './Header'
import TruckPositioner from './TruckPositioner/TruckPositioner'

const AssemblyLine = () => {
  const variants = {
    before: {
      x: '120vw',
      transition: {
        type: 'spring',
        mass: 3,
        damping: 60,
        stiffness: 500,
      },
    },
    during: {
      x: 0,
      scale: 1,
      transition: {
        type: 'spring',
        mass: 5,
        damping: 60,
        stiffness: 300,
      },
    },
    after: {
      x: '-37.5vw',
      scale: 0.5,
      transition: {
        type: 'spring',
        mass: 5,
        damping: 60,
        stiffness: 300,
      },
    },
  }

  let animate
  switch (factoryState.currentStep) {
    case 'envelope':
    case 'welcomeMessage':
      animate = 'before'
      break
    case 'charitySelection':
    case 'complete':
      animate = 'after'
      break
    default:
      animate = 'during'
  }

  return (
    <AssemblyLineWrapper>
      <Header animate={animate} />
      <AssemblyLineYPositioner factoryStep={factoryState.currentStep}>
        <StyledAssemblyLine
          initial="before"
          animate={animate}
          variants={variants}
        >
          <GiftPositioner />
          <ConveyorBelt />
          <TruckPositioner />
        </StyledAssemblyLine>
      </AssemblyLineYPositioner>
      <Controls animate={animate} />
    </AssemblyLineWrapper>
  )
}

export default observer(AssemblyLine)
