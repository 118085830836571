import React, { useState } from 'react'
import { observer } from 'mobx-react'

import factoryState from '/state/factoryState'
import RollerSvg from '/asset/svg/assemblyLine/roller.svg'

import { StyledRoller } from './Roller.styles'
import { randomBetween } from '/util/random'
import { capitalize } from '/util/string'

const Roller = () => {
  const initialRot = randomBetween(0, 180)
  const [rotTarget, setRotTarget] = useState(initialRot + 180)

  const variants = {
    forward: {
      rotate: rotTarget,
      transition: {
        repeatType: 'loop',
        repeat: Infinity,
        type: 'tween',
        duration: 0.4,
        ease: 'linear',
      },
    },
    backward: {
      rotate: rotTarget - 360,
      transition: {
        repeatType: 'loop',
        repeat: Infinity,
        type: 'tween',
        duration: 0.4,
        ease: 'linear',
      },
    },
    idleForward: {
      rotate: initialRot,
      transition: {
        type: 'inertia',
        velocity: 60,
        timeConstant: 240,
        modifyTarget: (target) => {
          setRotTarget(target + 180)
          return target
        },
      },
    },
    idleBackward: {
      rotate: initialRot - 180,
      transition: {
        type: 'inertia',
        velocity: -60,
        timeConstant: 240,
        modifyTarget: (target) => {
          setRotTarget(target + 180)
          return target
        },
      },
    },
  }

  let animate
  if (factoryState.conveyorRolling) {
    animate = factoryState.conveyorRolling
  } else {
    animate = `idle${capitalize(factoryState.prevConveyorDirection)}`
  }

  return (
    <StyledRoller variants={variants} animate={animate} initial="idleForward">
      <RollerSvg />
    </StyledRoller>
  )
}

export default observer(Roller)
