import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

export const StyledProgressBar = styled(motion.div)`
  position: relative;
  width: 80px;
  height: 24px;
  border-radius: 150px;

  @media (min-width: 360px) {
    width: 96px;
    height: 30px;
  }

  @media (min-width: 480px) {
    width: 120px;
    height: 36px;
  }

  @media (min-width: 1260px) {
    width: 160px;
    height: 48px;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    left: 0;
    right: 0;
    position: absolute;
  }

  // Depth
  &:before {
    border-radius: 0 0 150px 150px;
    top: 50%;
    bottom: -4px;
    z-index: -2;
    background: linear-gradient(180deg, #9f864e 50%, #edc25f 100%);
  }

  // BG
  &:after {
    border-radius: 150px;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(180deg, #ffffff 0%, #edd49a 100%);
  }
`

export const ProgressBarTrack = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  background: #1c1530;
  border-radius: 150px;
`

export const ProgressBarBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 150px 0 0 150px;
  background: linear-gradient(180deg, #991e1f 0%, #e54937 100%);
  box-shadow: inset 0px 2px 0px #ce282a, inset 0px -2px 0px #ff7777;
  transition: all 360ms ease-out;
  opacity: 1;

  ${(props) =>
    props.progressPercent === 0 &&
    css`
      opacity: 0;
    `}

  ${(props) =>
    props.progressPercent === 100 &&
    css`
      border-radius: 150px;
    `}

  ${(props) => css`
    width: ${props.progressPercent}%;
  `}
`
