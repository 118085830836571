import { motion } from 'framer-motion'
import styled from 'styled-components'

export const StyledConveyor = styled(motion.div)`
  background: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-top: 4px solid #38364e;
  border-bottom: 4px solid #38364e;
  position: relative;
  z-index: 3;

  @media (min-width: 360px) {
    border-top-width: 5px;
    border-bottom-width: 5px;
  }

  @media (min-width: 480px) {
    border-top-width: 6px;
    border-bottom-width: 6px;
  }

  @media (min-width: 1260px) and (min-height: 900px) {
    border-top-width: 10px;
    border-bottom-width: 10px;
  }
`

const Endcap = styled.div`
  position: absolute;
  top: -4px;
  background: #000;
  border: 4px solid #38364e;

  @media (min-width: 360px) {
    top: -5px;
    border-width: 5px;
  }

  @media (min-width: 480px) {
    top: -6px;
    border-width: 6px;
  }

  @media (min-width: 1260px) and (min-height: 900px) {
    top: -10px;
    border-width: 10px;
  }
`

export const LeftEndcap = styled(Endcap)`
  left: 0;
  transform: translateX(calc(-100% + 1px));
  border-radius: 150px 0 0 150px;
  border-right: 0;
`
export const RightEndcap = styled(Endcap)`
  right: 0;
  transform: translateX(calc(100% - 1px));
  border-radius: 0 150px 150px 0;
  border-left: 0;
`
