import { motion } from 'framer-motion'
import styled from 'styled-components'

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const Loader = styled(motion.div)`
  width: 42px;
  height: 42px;
  border-radius: 300px;
  background: rgba(255, 255, 255, 0.5);
`
