import React from 'react'

import MetaLabLogo from '/asset/svg/metaLabLogo.svg'

import ProgressBar from '../ProgressBar'
import { StyledHeader } from './Header.styles'

const Header = ({ animate }) => {
  const variants = {
    before: {
      opacity: 0,
      transition: {
        type: 'spring',
        mass: 3,
        damping: 60,
        stiffness: 500,
      },
    },
    during: {
      opacity: 1,
      transition: {
        type: 'spring',
        mass: 5,
        damping: 60,
        stiffness: 300,
      },
    },
    after: {
      opacity: 0,
      transition: {
        type: 'spring',
        mass: 5,
        damping: 60,
        stiffness: 300,
      },
    },
  }

  return (
    <StyledHeader animate={animate} variants={variants} initial="before">
      <MetaLabLogo />
      <ProgressBar />
    </StyledHeader>
  )
}

export default Header
