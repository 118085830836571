import { motion } from 'framer-motion'
import styled from 'styled-components'
import { StyledProgressBar } from '../ProgressBar/ProgressBar.styles'

export const StyledHeader = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: 720px;

  > svg {
    width: 32px;
    height: 32px;

    @media (min-width: 360px) {
      width: 38px;
      height: 38px;
    }

    @media (min-width: 480px) {
      width: 48px;
      height: 48px;
    }

    @media (min-width: 1260px) {
      width: 64px;
      height: 64px;
    }
  }

  > ${StyledProgressBar} {
    transform: translateY(-4px);
  }
`
