import React, { useState } from 'react'
import { observer } from 'mobx-react'

import giftState from '/state/giftState'
import factoryState from '/state/factoryState'
import IconButton from '/component/base/IconButton'

import GiftOptions from './GiftOptions'
import {
  StyledControls,
  Navigation,
  GiftOptionsWrapper,
  GiftOptionsConfirmation,
  GiftOptionsDepth,
  GiftOptions as GiftOptionsStyle,
  Bolt,
} from './Controls.styles'
import { AnimatePresence } from 'framer-motion'

import participantState from '/state/participantState'

import housePng from '/asset/png/paper/icon/house.png'
import deerPng from '/asset/png/paper/icon/deer.png'
import flowerPng from '/asset/png/paper/icon/flower.png'
import ornamentPng from '/asset/png/paper/icon/ornament.png'

import bowPng from '/asset/png/topper/icon/bow.png'
import snowflakePng from '/asset/png/topper/icon/snowflake.png'
import giftPng from '/asset/png/topper/icon/gift.png'
import treePng from '/asset/png/topper/icon/tree.png'

import classicTagPng from '/asset/png/tag/icon/classic.png'
import ornamentTagPng from '/asset/png/tag/icon/ornament.png'
import snowGolemTagPng from '/asset/png/tag/icon/snowGolem.png'
import treeTagPng from '/asset/png/tag/icon/tree.png'
import apiClient from '../../../../util/apiClient'

const Controls = ({ animate }) => {
  const [processing, setProcessing] = useState(false)
  const giftOptionsHidden = { opacity: 0 }
  const giftOptionsVisible = { opacity: 1 }
  let showGiftOptions
  switch (factoryState.currentStep) {
    case 'giftPaper':
    case 'giftTopper':
    case 'giftTag':
      showGiftOptions = true
      break
    default:
      showGiftOptions = false
  }

  const optsConfirmationHidden = { opacity: 0 }
  const optsConfirmationVisible = { opacity: 1 }
  let showOptsConfirmation
  switch (factoryState.currentStep) {
    case 'giftConfirmation':
      showOptsConfirmation = true
      break
    default:
      showOptsConfirmation = false
  }

  const variants = {
    before: {
      opacity: 0,
      transition: {
        type: 'spring',
        mass: 3,
        damping: 60,
        stiffness: 500,
      },
    },
    during: {
      opacity: 1,
      transition: {
        type: 'spring',
        mass: 5,
        damping: 60,
        stiffness: 300,
      },
    },
    after: {
      opacity: 0,
      transition: {
        type: 'spring',
        mass: 5,
        damping: 60,
        stiffness: 300,
      },
    },
  }

  const iconButtonHover = { scale: 1.05 }
  const iconButtonTap = { scale: 0.95 }

  const goPrev = () => factoryState.goPrev()
  const goNext = () => factoryState.goNext()

  let currentStepText
  switch (factoryState.currentStep) {
    case 'giftPaper':
      currentStepText = 'Choose a paper'
      break
    case 'giftTopper':
      currentStepText = 'Choose a topper'
      break
    case 'giftTag':
      currentStepText = 'Choose a tag'
      break
    default:
      break
  }

  let disableNextButton = false
  if (factoryState.currentStep === 'giftPaper' && !giftState.paper) {
    disableNextButton = true
  }

  const confirmMessage =
    participantState.name && !participantState.submissionComplete
      ? `If you like it too, then it's time to pick a\u00A0destination…`
      : `If you like it too, then it's time to ship it\u00A0out!`

  const handleDoneClick = async () => {
    if (participantState.name) {
      goNext()
    } else {
      setProcessing(true)
      await apiClient.addAnonymousResponse({
        paper: giftState.paper,
        topper: giftState.topper,
        tag: giftState.tag,
      })
      goNext()
    }
  }

  return (
    <StyledControls variants={variants} animate={animate} initial="before">
      <AnimatePresence exitBeforeEnter={true}>
        {showGiftOptions && (
          <GiftOptionsWrapper
            key="giftOptionsWrapper"
            animate={giftOptionsVisible}
            initial={giftOptionsHidden}
            exit={giftOptionsHidden}
          >
            <GiftOptionsDepth />
            <GiftOptionsStyle>
              <Bolt position="tl" />
              <Bolt position="tr" />
              <Bolt position="br" />
              <Bolt position="bl" />

              <AnimatePresence exitBeforeEnter={true}>
                {factoryState.currentStep === 'giftPaper' && (
                  <GiftOptions
                    optionTitle="Paper"
                    optionKey="paper"
                    key="paperOptions"
                    options={[
                      { name: 'house', iconSrc: housePng },
                      { name: 'deer', iconSrc: deerPng },
                      { name: 'flower', iconSrc: flowerPng },
                      { name: 'ornament', iconSrc: ornamentPng },
                    ]}
                  />
                )}
                {factoryState.currentStep === 'giftTopper' && (
                  <GiftOptions
                    optionTitle="Topper"
                    optionKey="topper"
                    key="topperOptions"
                    options={[
                      { name: 'bow', iconSrc: bowPng },
                      { name: 'snowflake', iconSrc: snowflakePng },
                      { name: 'gift', iconSrc: giftPng },
                      { name: 'tree', iconSrc: treePng },
                    ]}
                  />
                )}
                {factoryState.currentStep === 'giftTag' && (
                  <GiftOptions
                    optionTitle="Tag"
                    optionKey="tag"
                    key="tagOptions"
                    options={[
                      { name: 'classic', iconSrc: classicTagPng },
                      { name: 'ornament', iconSrc: ornamentTagPng },
                      { name: 'snowGolem', iconSrc: snowGolemTagPng },
                      { name: 'tree', iconSrc: treeTagPng },
                    ]}
                  />
                )}
              </AnimatePresence>
            </GiftOptionsStyle>
          </GiftOptionsWrapper>
        )}
        {showOptsConfirmation && (
          <GiftOptionsConfirmation
            key="giftOptionsConfirmation"
            animate={optsConfirmationVisible}
            initial={optsConfirmationHidden}
            exit={optsConfirmationHidden}
          >
            <p>Looks great!</p>
            <p>{confirmMessage}</p>
          </GiftOptionsConfirmation>
        )}
      </AnimatePresence>

      <Navigation>
        <div className="prev">
          <IconButton
            whileHover={iconButtonHover}
            whileTap={iconButtonTap}
            name="prev"
            onClick={goPrev}
          />
        </div>

        <p>{currentStepText}</p>

        <div className="next">
          {!['giftConfirmation', 'charitySelection', 'complete'].includes(
            factoryState.currentStep,
          ) && (
            <IconButton
              whileHover={iconButtonHover}
              whileTap={iconButtonTap}
              name="next"
              onClick={goNext}
              disabled={disableNextButton}
            />
          )}
          {factoryState.currentStep === 'giftConfirmation' && (
            <IconButton
              whileHover={iconButtonHover}
              whileTap={iconButtonTap}
              name="done"
              onClick={handleDoneClick}
              disabled={processing}
            />
          )}
        </div>
      </Navigation>
    </StyledControls>
  )
}

export default observer(Controls)
