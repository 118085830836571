import styled from 'styled-components'
import { motion } from 'framer-motion'

export const TruckWrapper = styled(motion.div)`
  width: 100%;
  top: 2px;

  &:after {
    content: '';
    display: block;
    padding-top: 56.9%;
  }

  img {
    position: absolute;
    display: block;
  }
`

export const BodyBackground = styled.img`
  top: 0;
  left: 1.65%;
  width: calc(30.5% + 1px);

  &:after {
    content: '';
    display: block;
    padding-top: 75.5%;
  }
`

export const BodyForeground = styled.img`
  top: 0;
  right: 1.65%;
  width: calc(90.9% + 1px);
  z-index: 3;

  &:after {
    content: '';
    display: block;
    padding-top: 75.5%;
  }
`

export const Chassis = styled.img`
  width: 100%;
  bottom: 1px;
  left: 0;
  z-index: 3;
`

export const DoorFrame = styled.div`
  position: absolute;
  width: 24.7%;
  z-index: 2;
  overflow: hidden;
  left: 9.07%;
  top: calc(13.04% - 1px);
  bottom: 28.99%;

  &:after {
    content: '';
    display: block;
    top: 0;
    right: 0;
    left: -1px;
    height: 8.75%;
    background: #4a485a;
    opacity: 0.7;
  }
`

export const Door = styled(motion.img)`
  width: 100%;
  bottom: 100%;
  transform-origin: top center;
`
