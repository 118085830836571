import React from 'react'

import { StyledButton } from './Button.styles'

const Button = ({ children, href, ...restProps }) => {
  if (!!href) {
    return (
      <StyledButton
        href={href}
        target="_blank"
        rel="noreferrer"
        as="a"
        {...restProps}
      >
        {children}
      </StyledButton>
    )
  } else {
    return <StyledButton {...restProps}>{children}</StyledButton>
  }
}

export default Button
