import { makeAutoObservable } from 'mobx'

class GiftState {
  paper = null
  topper = null
  tag = null
  charityName = null
  charityUrl = null

  constructor() {
    this.charityName = window.localStorage.charityName || null
    this.charityUrl = window.localStorage.charityUrl || null
    makeAutoObservable(this)
  }

  get(key) {
    return this[key]
  }

  setPaper(name) {
    this.paper = name
  }

  setTopper(name) {
    this.topper = name
  }

  setTag(name) {
    this.tag = name
  }

  setCharityName(charityName) {
    this.charityName = charityName
    window.localStorage.charityName = charityName
  }

  setCharityUrl(charityUrl) {
    this.charityUrl = charityUrl
    window.localStorage.charityUrl = charityUrl
  }
}

export default new GiftState()
