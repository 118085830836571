import React from 'react'

import NextSvg from '/asset/svg/icon/next.svg'
import PrevSvg from '/asset/svg/icon/prev.svg'
import DoneSvg from '/asset/svg/icon/done.svg'

import { Wrapper, Depth, StyledIconButton } from './IconButton.styles'
import { capitalize } from '/util/string'

const IconButton = ({ name, disabled, whileHover, whileTap, ...restProps }) => {
  return (
    <Wrapper disabled={disabled} whileHover={whileHover} whileTap={whileTap}>
      <StyledIconButton
        disabled={disabled}
        {...restProps}
        aria-label={capitalize(name)}
      >
        {name === 'next' && <NextSvg />}
        {name === 'prev' && <PrevSvg />}
        {name === 'done' && <DoneSvg />}
      </StyledIconButton>
      <Depth />
    </Wrapper>
  )
}

export default IconButton
