import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const AssemblyLineWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const AssemblyLineYPositioner = styled.div`
  position: absolute;
  top: 50%;
  width: 180vw;
  transition: all 360ms ease-in-out;
  transform: translateY(-65%);

  @media (min-width: 1260px) and (min-height: 900px) {
    transform: translateY(-45%);
  }

  ${(props) =>
    props.factoryStep === 'charitySelection' &&
    css`
      transform: translateY(-120%);

      @media (min-width: 960px) {
        transform: translateY(-65%);
      }

      @media (min-width: 1260px) {
        transform: translateY(-75%);
      }

      @media (min-width: 1260px) and (min-height: 900px) {
        transform: translateY(-80%);
      }
    `}
`

export const StyledAssemblyLine = styled(motion.div)`
  width: 100%;
  position: relative;
`
