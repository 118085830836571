import React from 'react'

import { Wrapper } from './LandscapeOverlay.styles'

const LandscapeOverlay = () => {
  return (
    <Wrapper>
      <p>Please view in a taller browser window / rotate your&nbsp;device</p>
    </Wrapper>
  )
}

export default LandscapeOverlay
