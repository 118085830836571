// Both inclusive, from:
// https://stackoverflow.com/questions/4959975/generate-random-number-between-two-numbers-in-javascript/63605936#63605936
export const randomBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const randomListBetween = (min, max, length) => {
  const out = []

  for (let i = 0; i < length; i++) {
    out.push(randomBetween(min, max))
  }

  return out
}
