import React from 'react'

import {
  BodyBackground,
  BodyForeground,
  TruckWrapper,
  Chassis,
  DoorFrame,
  Door,
} from './Truck.styles'

import bodyBackgroundPng from '/asset/png/truck/bodyBackground.png'
import bodyForegroundPng from '/asset/png/truck/bodyForeground.png'
import chassisPng from '/asset/png/truck/chassis.png'
import doorPng from '/asset/png/truck/door.png'

const Truck = ({ doorOpen, doorCloseDelay = 0, doorOpenDelay = 0 }) => {
  const doorVariants = {
    open: {
      y: '13%',
      transition: {
        type: 'spring',
        damping: 55,
        mass: 3,
        stiffness: 200,
        delay: doorOpenDelay,
      },
    },
    closed: {
      y: '72.727%',
      transition: {
        type: 'spring',
        damping: 40,
        mass: 3,
        stiffness: 250,
        delay: doorCloseDelay,
      },
    },
  }

  return (
    <TruckWrapper>
      <BodyBackground src={bodyBackgroundPng} />
      <BodyForeground src={bodyForegroundPng} />
      <Chassis src={chassisPng} />
      <DoorFrame>
        <Door
          src={doorPng}
          variants={doorVariants}
          animate={doorOpen ? 'open' : 'closed'}
          initial={false}
        />
      </DoorFrame>
    </TruckWrapper>
  )
}

export default Truck
