import { motion } from 'framer-motion'
import styled from 'styled-components'

export const LetterWrapper = styled.div`
  position: absolute;
  top: -75%;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 2;

  @media (min-width: 480px) {
    top: -60%;
  }

  @media (min-width: 1260px) {
    top: -57%;
  }
`

export const StyledLetter = styled(motion.div)`
  margin-left: 10%;
  margin-right: 10%;
  color: #060601;
  text-align: center;
  padding-top: 30px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 30%;
  border-radius: 8px 8px 0 0;

  p {
    margin-bottom: 16px;
    font-size: 16px;

    @media (min-width: 480px) {
      font-size: 20px;
    }

    @media (min-width: 1260px) {
      font-size: 26px;
    }
  }

  button {
    margin-top: 16px;
  }

  &:before,
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    content: '';
    border-radius: 8px 8px 0 0;
    z-index: -1;
  }

  &:before {
    background: linear-gradient(
      270deg,
      #9f864e 0%,
      #edc35f 38.89%,
      #edc35f 61.09%,
      #9f864e 100%
    );
    top: -4px;
    height: 50px;
  }

  &:after {
    background: #fff;
    top: 0;
    height: 100%;
  }
`
