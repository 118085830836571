import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

export const Wrapper = styled(motion.div)`
  position: relative;
  width: 48px;
  height: 48px;

  @media (min-width: 360px) {
    width: 56px;
    height: 56px;
  }

  @media (min-width: 480px) {
    width: 64px;
    height: 64px;
  }

  @media (min-width: 768px) {
    width: 96px;
    height: 96px;
  }
`

export const Depth = styled(motion.div)`
  z-index: 1;
  background: linear-gradient(#edc25f, #9f864e);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 150px;
  transition: all 60ms ease-out;
  box-shadow: 0 2px #f0f2d8;

  @media (min-width: 768px) {
    box-shadow: 0 3px #f0f2d8;
  }

  ${(props) =>
    props.selected &&
    css`
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border-radius: 150px;
      box-shadow: 0 3px #ecc764;

      @media (min-width: 768px) {
        width: calc(100% + 16px);
        height: calc(100% + 16px);
        box-shadow: 0 6px #ecc764;
      }
    `};
`

export const StyledRadioButton = styled.button`
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 150px;
  cursor: pointer;
  position: relative;
`
