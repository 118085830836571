import { motion } from 'framer-motion'
import styled from 'styled-components'

export const StyledRoller = styled(motion.div)`
  position: relative;
  height: 40px;
  width: 40px;

  img {
    vertical-align: bottom;
  }

  @media (min-width: 360px) {
    height: 48px;
    width: 48px;
  }

  @media (min-width: 480px) {
    height: 60px;
    width: 60px;
  }

  @media (min-width: 1260px) and (min-height: 900px) {
    height: 100px;
    width: 100px;
  }
`
