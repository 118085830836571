import { observer } from 'mobx-react'
import React, { useRef } from 'react'
import { AnimatePresence } from 'framer-motion'

import factoryState from '/state/factoryState'
import EnvelopeOutsideSvg from '/asset/svg/envelope/outside.svg'
import EnvelopeInsideSvg from '/asset/svg/envelope/inside.svg'
import { randomListBetween } from '/util/random'

import {
  Wrapper,
  StyledEnvelope,
  EnvelopeInner,
  InitialButton,
} from './Envelope.styles'
import Letter from './Letter'
import Flap from './Flap'

const Envelope = () => {
  const ref = useRef(null)

  const variants = {
    initial: {
      x: '0%',
      y: '6%',
      scale: 1,
      opacity: 1,
      rotate: 0,
    },
    closed: {
      x: randomListBetween(-100, 100, 5).map((n) => `${n / 100}%`),
      y: [5, -5].map((n) => `${n}%`),
      scale: randomListBetween(990, 1010, 5).map((n) => n / 1000),
      opacity: 1,
      rotate: randomListBetween(-150, 150, 5).map((n) => n / 100),
      transition: {
        repeatType: 'mirror',
        repeat: Infinity,
        duration: 3,
        type: 'tween',
        ease: 'easeInOut',
      },
    },
    open: {
      x: 0,
      y: '20%',
      scale: 1,
      opacity: 1,
      rotate: 0,
      transition: {
        type: 'spring',
        mass: 5,
        damping: 60,
        stiffness: 200,
      },
    },
    after: {
      x: '-200vw',
      scale: 0.5,
      opacity: 0,
      y: '-20%',
      rotate: 15,
      transition: {
        type: 'spring',
        mass: 5,
        damping: 60,
        stiffness: 150,
      },
    },
  }

  let animate
  switch (factoryState.currentStep) {
    case 'envelope':
      animate = 'closed'
      break
    case 'welcomeMessage':
      animate = 'open'
      break
    default:
      animate = 'after'
  }

  const isVisible = ['envelope', 'welcomeMessage'].includes(
    factoryState.currentStep,
  )

  return (
    <AnimatePresence>
      {isVisible && (
        <Wrapper
          animate={{ pointerEvents: 'auto' }}
          exit={{ pointerEvents: 'none' }}
        >
          <div>
            <StyledEnvelope
              ref={ref}
              variants={variants}
              animate={animate}
              exit="after"
              initial="initial"
              open={['open', 'after'].includes(animate)}
            >
              <EnvelopeInner open={factoryState.currentStep !== 'envelope'}>
                <EnvelopeInsideSvg
                  style={{
                    width: '100%',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    height: '100%',
                    zIndex: 1,
                  }}
                />
                <Letter />
                <EnvelopeOutsideSvg
                  style={{
                    width: '100%',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    zIndex: 3,
                    transform: 'translateZ(1px)',
                    pointerEvents: 'none',
                  }}
                />
                <Flap />
              </EnvelopeInner>
              {factoryState.currentStep === 'envelope' && (
                <InitialButton
                  aria-label="Open envelope"
                  onClick={() => factoryState.goNext()}
                />
              )}
            </StyledEnvelope>
          </div>
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

export default observer(Envelope)
