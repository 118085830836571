import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const Wrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`

export const StyledEnvelope = styled(motion.div)`
  position: relative;
  width: 210px;
  height: 100%;
  color: #fff;
  perspective: 600px;
  transition: width 360ms ease-in-out;

  @media (min-width: 480px) {
    width: 300px;
  }

  @media (min-width: 1260px) {
    width: 400px;
  }

  ${(props) =>
    props.open &&
    css`
      width: 380px;

      @media (min-width: 480px) {
        width: 480px;
      }

      @media (min-width: 1260px) {
        width: 620px;
      }
    `}

  &:after {
    content: '';
    display: block;
    padding-top: 66.2%;
  }
`

export const EnvelopeInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  > svg:first-child {
    border-radius: 24px;
    transition: border-radius 900ms linear;

    ${(props) =>
      props.open &&
      css`
        transition: border-radius 300ms linear;
        border-radius: 0 0 24px 24px;
      `}
  }
`
export const InitialButton = styled.button`
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: transparent;
`
