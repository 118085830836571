import React from 'react'
import { TopperWrapper } from './Topper.styles'

import bowTopperPng from '/asset/png/topper/bow.png'
import snowflakeTopperPng from '/asset/png/topper/snowflake.png'
import giftTopperPng from '/asset/png/topper/gift.png'
import treeTopperPng from '/asset/png/topper/tree.png'

import giftState from '/state/giftState'
import { observer } from 'mobx-react'
import { AnimatePresence, motion } from 'framer-motion'

const Topper = () => {
  const topperStyle = { width: '100%', height: '100%' }

  return (
    <TopperWrapper>
      <AnimatePresence exitBeforeEnter>
        {giftState.topper && (
          <motion.div
            exit={{ opacity: -0.5, scale: 0.3, y: '10%' }}
            initial={{ opacity: -0.5, scale: 0.8, y: '100%' }}
            animate={{ opacity: 1, scale: 1, y: '0.5px' }}
            key={giftState.topper}
          >
            {giftState.topper === 'bow' && (
              <img src={bowTopperPng} alt="Bow topper" style={topperStyle} />
            )}
            {giftState.topper === 'snowflake' && (
              <img
                src={snowflakeTopperPng}
                alt="Snowflake topper"
                style={topperStyle}
              />
            )}
            {giftState.topper === 'gift' && (
              <img src={giftTopperPng} alt="Gift topper" style={topperStyle} />
            )}
            {giftState.topper === 'tree' && (
              <img src={treeTopperPng} alt="Tree topper" style={topperStyle} />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </TopperWrapper>
  )
}

export default observer(Topper)
