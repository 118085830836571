import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import giftState from '/state/giftState'

import houseBoxPng from '/asset/png/paper/box/house.png'
import deerBoxPng from '/asset/png/paper/box/deer.png'
import flowerBoxPng from '/asset/png/paper/box/flower.png'
import ornamentBoxPng from '/asset/png/paper/box/ornament.png'

import starterGiftPng from '/asset/png/starterGift.png'

import Topper from './Topper'
import Tag from './Tag'
import Lid from './Lid'
import { GiftImg } from './Gift.styles'
import { observer } from 'mobx-react'

const Gift = () => {
  return (
    <AnimatePresence>
      {!giftState.paper && (
        <motion.div
          key="starter"
          exit={{ opacity: 0, scale: 0.4, y: '10%' }}
          style={{
            height: '135%',
            position: 'absolute',
            bottom: 0,
          }}
          transition={{ type: 'tween', duration: 0.2 }}
        >
          <img
            src={starterGiftPng}
            alt="Unwrapped present"
            style={{ height: '100%' }}
          />
        </motion.div>
      )}
      {!!giftState.paper && (
        <motion.div key="paper" style={{ width: '100%', height: '100%' }}>
          <AnimatePresence style={{ width: '100%', height: '100%' }}>
            <motion.div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              key={giftState.paper}
              exit={{
                rotate: [0, -2, 0, 2, 0],
                y: ['0%', '-2%', '-8%', '-2%', '0%'],
                scale: [1, 1.02, 1.05, 1.02, 1],
                x: ['0%', '-1%', '0%', '1%', '0%'],
                opacity: [1, 1, 0.8, 0.6, 0.4],
              }}
              animate={{
                rotate: [0, -2, 0, 2, 0],
                y: ['0%', '-2%', '-8%', '-2%', '0%'],
                scale: [1, 1.02, 1.05, 1.02, 1],
                x: ['0%', '-1%', '0%', '1%', '0%'],
                opacity: [0.4, 0.6, 0.8, 1, 1],
              }}
              initial={{
                scale: 1,
                x: '0%',
                y: '0%',
                rotate: 0,
                opacity: 0.2,
              }}
              transition={{
                type: 'tween',
                duration: 0.25,
                ease: 'easeInOut',
              }}
            >
              {giftState.paper === 'house' && (
                <GiftImg
                  src={houseBoxPng}
                  alt="Present wrapped with house paper"
                />
              )}
              {giftState.paper === 'deer' && (
                <GiftImg
                  src={deerBoxPng}
                  alt="Present wrapped with deer paper"
                />
              )}
              {giftState.paper === 'flower' && (
                <GiftImg
                  src={flowerBoxPng}
                  alt="Present wrapped with flower paper"
                />
              )}
              {giftState.paper === 'ornament' && (
                <GiftImg
                  src={ornamentBoxPng}
                  alt="Present wrapped with ornament paper"
                />
              )}

              <Lid>
                <Topper />
                <Tag />
              </Lid>
            </motion.div>
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default observer(Gift)
