import styled, { css } from 'styled-components'

export const StyledTruckPositioner = styled.div`
  top: -112%;
  left: 50%;
  position: absolute;
  width: 800px;
  transition: all 700ms ease-out;
  transition-delay: 0;

  @media (min-width: 360px) {
    top: -106%;
    width: 880px;
  }

  @media (min-width: 480px) {
    top: -159%;
    width: 1400px;
  }

  @media (min-width: 1260px) and (min-height: 900px) {
    top: -112%;
    width: 2000px;
  }

  ${(props) =>
    !props.isOnScreen &&
    css`
      left: 150%;
    `}

  ${(props) =>
    props.isOnScreen &&
    css`
      left: calc(90vw - 10%);
    `}

  ${(props) =>
    props.isComplete &&
    css`
      transition-delay: 2000ms;
    `}
`
