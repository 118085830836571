import { makeAutoObservable } from 'mobx'

import participantState from '/state/participantState'

export const steps = [
  'envelope',
  'welcomeMessage',
  'giftPaper',
  'giftTopper',
  'giftTag',
  'giftConfirmation',
  'charitySelection',
  'complete',
]

const autoOpenDelay = 2000

class FactoryState {
  prevConveyorDirection = 'forward'
  conveyorRolling = false
  currentStepIndex = 0
  navigationLocked = false

  get currentStep() {
    return steps[this.currentStepIndex]
  }

  constructor() {
    makeAutoObservable(this)
    setTimeout(this.autoOpen, autoOpenDelay)
  }

  autoOpen = () => {
    if (this.currentStep === 'envelope') {
      this.goNext()
    }
  }

  goNext() {
    if (this.navigationLocked || this.currentStepIndex >= steps.length - 1) {
      return false
    }

    this._goTo(this.currentStepIndex + 1)

    return true
  }

  goPrev() {
    if (this.navigationLocked || this.currentStepIndex <= 0) {
      return false
    }

    this._goTo(this.currentStepIndex - 1)

    return true
  }

  _goTo(newStepIndex) {
    const direction =
      newStepIndex > this.currentStepIndex ? 'forward' : 'backward'
    this._leaveStep(this.currentStepIndex, direction)
    this._enterStep(newStepIndex, direction)

    this.currentStepIndex = newStepIndex
  }

  _leaveStep(stepIndex, direction) {
    const stepName = steps[stepIndex]

    switch (stepName) {
      default:
        break
    }
  }

  _enterStep(stepIndex, direction) {
    const stepName = steps[stepIndex]

    switch (stepName) {
      case 'giftPaper':
        if (direction === 'backward') {
          this._activateConveyor(direction)
        }
        break
      case 'giftTopper':
      case 'giftTag':
      case 'giftConfirmation':
      case 'complete':
        this._activateConveyor(direction)
        break
      case 'charitySelection':
        if (direction === 'forward') {
          this._activateConveyor(direction)
        }

        if (
          participantState.charities.length === 0 ||
          participantState.submissionComplete
        ) {
          setTimeout(() => this.goNext(), 1200)
        }
        break
      default:
        break
    }
  }

  _activateConveyor(direction) {
    this.prevConveyorDirection = direction
    this.conveyorRolling = direction
    this.navigationLocked = true

    setTimeout(() => {
      this.conveyorRolling = false
    }, 750)
    setTimeout(() => {
      this.navigationLocked = false
    }, 1000)
  }
}

export default new FactoryState()
