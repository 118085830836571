import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 30px;
  padding-bottom: 60px;
  z-index: 6;
  background: radial-gradient(88.75% 50% at 50% 50%, #ffffff 0%, #f1dcae 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${(props) =>
    props.anonymous &&
    css`
      @media (max-height: 480px),
        (min-width: 480px) and (max-height: 520px),
        (min-width: 768px) and (max-height: 620px) {
        justify-content: flex-start;
        overflow-y: auto;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
      }
    `}

  ${(props) =>
    !props.anonymous &&
    css`
      @media (max-height: 599px),
        (min-width: 480px) and (max-height: 767px),
        (min-width: 768px) and (max-height: 1024px) {
        justify-content: flex-start;
        overflow-y: auto;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
      }
    `}
`

export const Header = styled.h2`
  color: #060601;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1.5rem;
`

export const Paragraph = styled.p`
  color: #060601;
  font-size: 1rem;
  text-align: center;
  max-width: 550px;
  padding-left: 4%;
  padding-right: 4%;
  margin-bottom: 1.5rem;

  @media (min-width: 480px) {
    max-width: 650px;
  }

  @media (min-width: 768px) {
    max-width: 750px;
  }
`
