import React from 'react'
import { motion } from 'framer-motion'
import { observer } from 'mobx-react'

import factoryState from '/state/factoryState'
import Gift from '/component/base/Gift'

import { Outer, Wrapper, TopSpacing } from './GiftPositioner.styles'

const GiftPositioner = () => {
  const variants = {
    hidden: {
      y: '-600%',
    },
    visible: {
      y: '0%',
      transition: {
        delay: 2.5,
        type: 'spring',
        mass: 4,
        damping: 30,
        strength: 600,
      },
    },
  }

  const animate = factoryState.currentStep === 'complete' ? 'visible' : 'hidden'

  return (
    <Outer>
      <TopSpacing />
      <Wrapper>
        <motion.div
          variants={variants}
          animate={animate}
          initial="hidden"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            bottom: 0,
            left: 0,
          }}
        >
          <Gift />
        </motion.div>
      </Wrapper>
    </Outer>
  )
}

export default observer(GiftPositioner)
