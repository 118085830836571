import { motion } from 'framer-motion'
import styled from 'styled-components'

import { Wrapper as CharityButtonWrapper } from '/component/base/CharityButton/CharityButton.styles'

export const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 2vh;

  @media (min-width: 768px) {
    padding-bottom: 4vh;
  }

  @media (min-width: 960px) {
    padding-bottom: 8vh;
  }
`

export const CharityList = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  max-width: 500px;

  > ${CharityButtonWrapper} {
    margin-bottom: 2.5vh;
    width: 100%;

    @media (min-width: 768px) {
      margin-bottom: 3vh;
    }

    @media (min-width: 960px) {
      margin-bottom: 4vh;
      margin-left: 1vw;
      margin-right: 1vw;
    }
  }

  @media (min-width: 960px) {
    max-width: 1440px;
    flex-direction: row;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`
