import styled from 'styled-components'

export const Outer = styled.div`
  width: 200px;
`

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 32px;

  &:after {
    content: '';
    display: block;
    padding-top: 52%;
  }
`

export const TopSpacing = styled.div`
  &:after {
    content: '';
    display: block;
    padding-top: 46%;
  }
`
