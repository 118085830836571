import React from 'react'
import { observer } from 'mobx-react'
import Div100vh from 'react-div-100vh'

import participantState from '/state/participantState'
import Envelope from '/component/factory/Envelope'
import AssemblyLine from '/component/factory/AssemblyLine'
import CharityPicker from '/component/factory/CharityPicker'
import ThankYou from '/component/factory/ThankYou'
import LandscapeOverlay from '/component/factory/LandscapeOverlay'

import { LoaderWrapper, Loader } from './Factory.styles'
import { AnimatePresence } from 'framer-motion'

const Factory = () => {
  return (
    <>
      <Div100vh>
        <AnimatePresence exitBeforeEnter>
          {!participantState.isInitialized && (
            <LoaderWrapper>
              <Loader
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  y: [0, -10, 10],
                  x: [10, -10, 0],
                  scale: [1, 1.2, 1.2],
                }}
                transition={{
                  type: 'spring',
                  duration: 2,
                  repeatType: 'mirror',
                  repeat: Infinity,
                }}
                exit={{ opacity: 0 }}
              />
            </LoaderWrapper>
          )}
          {participantState.isInitialized && <Envelope />}
        </AnimatePresence>

        <AssemblyLine />
        <CharityPicker />
        <ThankYou />
        <LandscapeOverlay />
      </Div100vh>
    </>
  )
}

export default observer(Factory)
