import React from 'react'

import { Wrapper, Depth, StyledRadioButton } from './RadioButton.styles'
import { capitalize } from '/util/string'

const RadioButton = ({
  name,
  selected,
  children,
  initial,
  animate,
  exit,
  variants,
  whileTap,
  whileHover,
  custom,
  transition,
  ...restProps
}) => {
  return (
    <Wrapper
      initial={initial}
      animate={animate}
      exit={exit}
      variants={variants}
      transition={transition}
      whileTap={whileTap}
      whileHover={whileHover}
      custom={custom}
    >
      <StyledRadioButton {...restProps} aria-label={capitalize(name)}>
        {children}
      </StyledRadioButton>
      <Depth selected={selected} />
    </Wrapper>
  )
}

export default RadioButton
